<template>
  <div class="transferTakeover">
    <div class="card">
      <div class="header">
        <div class="item" :class="!activeType ? 'active' : ''">
          <div class="index">
            <span v-if="!activeType">1</span>
            <span v-else class="el-icon-check"></span>
          </div>
          {{ $t('Take over administrator information') }}
        </div>
        <div class="item" :class="activeType == 1 ? 'active' : ''">
          <div class="index">
            <span>2</span>
          </div>
          {{ $t('Modify company information') }}
        </div>
      </div>
      <div class="form" v-if="activeType == 0">
        <div class="userAvatarBox">
          <div class="imageBox">
            <img :src="accountForm.avatarUrl" />
            <div class="mask">
              <i class="el-icon-upload2"></i>
              <input type="file" accept="image/gif, image/jpeg, image/png" @change="confirmUploadLogo" />
            </div>
          </div>
          <div class="name">
            {{ accountForm.username }}
          </div>
        </div>
        <div class="formBox">
          <el-form 
            ref="profileFormRef" 
            :rules="accountRules" 
            :model="accountForm" 
            class="profileForm">
            <el-form-item prop="username">
              <div class="title">{{ $t('registerLoging.Username') }}</div>
              <el-input v-model="accountForm.username" :placeholder="$t('registerLoging.Username')" autocomplete="off" />
            </el-form-item>
            <el-form-item prop="nickName">
              <div class="title">{{ $t('EditMine.Name') }}</div>
              <el-input v-model="accountForm.nickName" :placeholder="$t('EditMine.Name')" autocomplete="off" />
            </el-form-item>
            <el-form-item prop="position">
              <div class="title">{{ $t('InformationCenter.Title') }}</div>
              <el-input v-model="accountForm.position" :placeholder="$t('InformationCenter.Title')" autocomplete="off" />
            </el-form-item>
            <el-form-item prop="country">
              <div class="title">{{ $t('EditMine.Country') }}</div>
              <el-select
                v-model="accountForm.country"
                filterable
                :placeholder="$t('customerInfo.Choose the country')"
                autocomplete="off"
                appendToBody
              >
                <el-option
                  v-for="item in country"
                  :key="item.iso3"
                  :label="item.key"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item prop="userEmail">
              <div class="title">{{ $t('EditMine.Email') }}</div>
              <el-input v-model="accountForm.userEmail" :placeholder="$t('people.Email')">
                <span 
                  class="bindEmail" 
                  slot="suffix"
                  @click="validatorEmail"
                >{{ $t('validate') }}</span>
              </el-input>
            </el-form-item>
            <el-form-item prop="timeZone">
              <div class="title">{{ $t('EditMine.Timezone') }}</div>
              <el-select
                v-model="accountForm.timeZone"
                filterable
                :placeholder="$t('customerInfo.Choose the timezone')"
                autocomplete="off"
                appendToBody
              >
                <el-option
                  v-for="item in timeZone"
                  :key="item.value"
                  :label="item.key"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="password">
              <div class="title">{{ $t('customerInfo.New password') }}</div>
              <el-input 
                v-model="accountForm.password" 
                type="password"
                show-password
                :placeholder="$t('customerInfo.New password')" 
                autocomplete="off" />
              <PasswordStrength
                :password="accountForm.password"
              ></PasswordStrength>
            </el-form-item>
            <el-form-item prop="confirmPassword">
              <div class="title"></div>
              <el-input 
                v-model="accountForm.confirmPassword" 
                type="password"
                :placeholder="$t('customerInfo.Confirm password')" 
                autocomplete="off" />
              <PasswordStrength
                :password="accountForm.confirmPassword"
              ></PasswordStrength>
            </el-form-item>
          </el-form>
        </div>
        <el-button type="primary" style="padding: 14px 80px;margin-top: 50px;" @click="userNext">{{ $t('order.Next') }}</el-button>
      </div>
      <div class="form company" v-if="activeType == 1">
        <div class="userAvatarBox company">
          <div class="imageBox">
            <img :src="companyForm.companyLogoUrl" />
            <div class="mask">
              <i class="el-icon-upload2"></i>
              <input type="file" accept="image/gif, image/jpeg, image/png" @change="confirmUploadLogo" />
            </div>
          </div>
          <div class="name">
            {{ companyForm.name }}
          </div>
        </div>
        <div class="formBox">
          <el-form 
            ref="companyFormRef" 
            :rules="companyRules" 
            :model="companyForm" 
            class="profileForm">
            <el-form-item prop="name">
              <div class="title">{{ $t('CompanyEdit.CompanyName') }}</div>
              <el-input v-model="companyForm.name" :placeholder="$t('home.Please enter')+$t('CompanyEdit.CompanyName')" autocomplete="off" />
            </el-form-item>
            <el-form-item prop="aliasName">
              <div class="title">{{ $t('CompanyEdit.CompanyAlias') }}</div>
              <el-input v-model="companyForm.aliasName" :placeholder="$t('CompanyEdit.CompanyAlias')" autocomplete="off" />
            </el-form-item>
            <el-form-item prop="companyType">
              <div class="title">{{ $t('InformationCenter.Title') }}</div>
              <el-select
                v-model="companyForm.companyType"
                appendToBody
                autocomplete="off"
                disabled
                :placeholder="$t('home.Please select')+$t('CompanyEdit.Type')"
              >
                <el-option
                  v-for="item in companyType"
                  :key="item.value"
                  :label="item.key"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item prop="country">
              <div class="title">{{ $t('EditMine.Country') }}</div>
              <el-select
                v-model="companyForm.companyCountry"
                filterable
                :placeholder="$t('customerInfo.Choose the country')"
                autocomplete="off"
                appendToBody
              >
                <el-option
                  v-for="item in country"
                  :key="item.iso3"
                  :label="item.key"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item prop="email">
              <div class="title">{{ $t('EditMine.Email') }}</div>
              <el-input v-model="companyForm.email" :placeholder="$t('people.Email')" />
            </el-form-item>
            <el-form-item prop="timeZone">
              <div class="title">{{ $t('EditMine.Timezone') }}</div>
              <el-select
                v-model="companyForm.companyTimeZone"
                filterable
                :placeholder="$t('customerInfo.Choose the timezone')"
                autocomplete="off"
                appendToBody
              >
                <el-option
                  v-for="item in timeZone"
                  :key="item.value"
                  :label="item.key"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="contact">
              <div class="title">{{ $t('CompanyEdit.Contact') }}</div>
              <el-input v-model="companyForm.contact" :placeholder="$t('home.Please enter')+$t('CompanyEdit.Contact')" autocomplete="off" />
            </el-form-item>
            <el-form-item prop="vat">
              <div class="title">{{ $t('CompanyEdit.VATNO') }}</div>
              <el-input v-model="companyForm.vat" :placeholder="$t('home.Please enter')+$t('CompanyEdit.VATNO')" autocomplete="off" />
            </el-form-item>
            <el-form-item prop="address">
              <div class="title">{{ $t('CompanyEdit.Address') }}</div>
              <el-input v-model="companyForm.address" :placeholder="$t('home.Please enter')+$t('CompanyEdit.Address')" autocomplete="off" />
            </el-form-item>
            <el-form-item prop="website">
              <div class="title">{{ $t('CompanyEdit.Website') }}</div>
              <el-input v-model="companyForm.website" :placeholder="$t('home.Please enter')+$t('CompanyEdit.Website')" autocomplete="off" />
            </el-form-item>
          </el-form>
          <el-button 
            type="primary" 
            style="padding: 14px 80px;margin-top: 50px;" 
            @click="submit"
          >{{ $t('Confirm takeover') }}</el-button>
          <div class="prevBtn" @click="prev">{{ $t('order.to previous step') }}</div>
        </div>
      </div>
    </div>
    <cropper
      :visible="isCropperVisible"
      :url="cropperImage"
      :fixed="!activeType"
      @close="isCropperVisible = false"
      @confirm="pictureHandler"
    />
    <validatorEmailDialog ref="validatorEmailDialog" :code.sync="accountForm.code" />
  </div>
</template>

<script>
import { getDict } from "@/api/common";
import { getLoginUserDetail } from "@/api";
import { uploadFile } from '@/api/files';
import { updateOauthTempUserData, getOfficeCompany } from "@/api/customer";
import validatorEmailDialog from "@/components/validatorEmailDialog";
export default {
  name: "transferTakeover",
  components: {
    PasswordStrength: () => import('@/components/passwordStrength/passwordStrength.vue'),
    cropper: () => import('@/components/cropper/cropper.vue'),
    validatorEmailDialog
  },
  data() {
    return {
      activeType: 0,
      country: [],
      timeZone: [],
      companyType: [],
      accountForm: {
        avatarFileId: "",
        avatarUrl: "",
        username: "",
        position: "",
        code: "",
        avatarFileId: "",
        nickName: "",
        userEmail: "",
        country: "",
        timeZone: "",
        password: "",
        confirmPassword: ""
      },
      accountRules: {
        username: [
          { required: true, message: this.$t('registerLoging.PleaseinputaUserName'), trigger: 'blur' },
          {
            min: 2,
            max: 64,
            message: this.$t('customerInfo.Length should be 2 to 64'),
            trigger: 'blur',
          },
        ],
        nickName: [
          { required: true, message: this.$t('customerInfo.Please input name'), trigger: 'blur' },
          {
            min: 2,
            max: 64,
            message: this.$t('customerInfo.Length should be 2 to 64'),
            trigger: 'blur',
          },
        ],
        position: [
          { required: true, message: this.$t('home.Please enter')+' '+this.$t('InformationCenter.Title'), trigger: 'blur' },
        ],
        userEmail: [
          { required: true, message: this.$t('registerLoging.PleaseinputaEmail'), trigger: 'blur' },
        ],
        password: [
          {
            validator: (rule, value, callback) => {
              if (value.length < 8) {
                callback(new Error(this.$t('customerInfo.Password must be at least 8 characters')));
              } else if (!/[A-Za-z]/.test(value)) {
                callback(
                  new Error(
                    this.$t('customerInfo.Password must contain at least one letter and one number')
                  )
                );
              } else {
                callback();
              }
            },
            trigger: 'blur',
          },
        ],
        confirmPassword: [
          {
            validator: (rule, value, callback) => {
              if (value.length < 8) {
                callback(new Error(this.$t('customerInfo.Password must be at least 8 characters')));
              } else if (value !== this.accountForm.password) {
                callback(new Error(this.$t('customerInfo.Two passwords are inconsistent!')));
              } else {
                callback();
              }
            },
            trigger: 'blur',
          },
        ],
      },
      cropperImage: "",
      isCropperVisible: false,
      companyRules: {
        vat: [
          {
            required: true,
            message: this.$t('home.PleaseinputVAT'),
            trigger: 'blur',
          },
          {
            pattern: /^[A-Za-z0-9]{1,20}$/,
            message: this.$t('home.PleaseinputcorrectVAT'),
            trigger: 'blur',
          },
        ],
        aliasName: [
          {
            required: true,
            message: this.$t('home.Pleaseinputaliasname'),
            trigger: 'blur',
          },
        ],
        companyType: [
          {
            required: true,
            message: this.$t('home.Pleaseselectcompanytype'),
            trigger: 'blur',
          },
        ],
        contact: [
          {
            required: true,
            message: this.$t('home.Please input contact'),
            trigger: 'blur',
          },
        ],
        companyCountry: [
          {
            required: true,
            message: this.$t('home.lease select country'),
            trigger: 'blur',
          },
        ],
        email: [
          {
            required: true,
            message: this.$t('home.Please input email'),
            trigger: 'blur',
          },
        ],
        name: [
          {
            required: true,
            message: this.$t('home.Please input name'),
            trigger: 'blur',
          },
        ],
        companyTimeZone: [
          {
            required: true,
            message: this.$t('home.Please select timezone'),
            trigger: 'blur',
          },
        ],
        address: [
          {
            required: true,
            message: this.$t('home.Please enter the detailed address'),
          },
        ],
        website: [{
          pattern: /^([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/)(([A-Za-z0-9-~]+).)+([A-Za-z0-9-~\/])+$/,
          message: window.vm.$t('people.Please enter the correct official website address'),
          trigger: 'blur',
        }]
      },
      companyForm: {
        companyLogoId: "",
        companyLogoUrl: "",
        name: "",
        aliasName: "",
        companyType: "",
        companyCountry: "",
        email: "",
        companyTimeZone: "",
        contact: "",
        vat: "",
        address: "",
        website: ""
      }
    }
  },
  computed: {

  },
  mounted() {
    this.getDict();
    this.getUserInfo();
  },
  methods: {
    validatorEmail() {
      this.$refs.validatorEmailDialog.open(this.accountForm.userEmail);
    },
    beforeAvatarUpload({ size, type }) {
      const isJPG =
          type === 'image/jpeg' ||
          type === 'image/png' ||
          type === 'image/jpg' ||
          type === 'image/gif';
      const isLt2M = size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error(window.vm.$t('customerInfo.Picture must be JPG or PNG or GIF!'));
      }
      if (!isLt2M) {
        this.$message.error(window.vm.$t('customerInfo.Picture size can not exceed 2MB!'));
      }
      return isJPG && isLt2M;
    },
    confirmUploadLogo(event) {
      let files = event.target.files[0]
      if (!this.beforeAvatarUpload(files)) return;
      this.cropperImage = URL.createObjectURL(files);
      this.isCropperVisible = true;
      event.target.value = "";
    },
    pictureHandler(file) {
      //只传一个文件的情况
      var data_up = new FormData();
      data_up.append('files', file);
      data_up.append('configCode', !this.activeType ? 'userImage' : 'companyProfile');
      uploadFile(data_up, this.$route.query.accessToken)
        .then((data) => {
          if (!data[0]) return;
          if(!this.activeType) {
            this.accountForm.avatarFileId = data[0];
            this.$nextTick(() => {
              this.accountForm.avatarUrl = URL.createObjectURL(file);
            })
          } else {
            this.companyForm.companyLogoId = data[0];
            this.$nextTick(() => {
              this.companyForm.companyLogoUrl = URL.createObjectURL(file);
            })
          }
          this.$message.success(this.$t('customerInfo.Upload success'));
          this.isCropperVisible = false;
        })
        .catch((error) => {});
    },
    getUserInfo() {
      getLoginUserDetail(this.$route.query.accessToken).then((data) => {
        this.accountForm = {
          ...data,
          userEmail: data.email,
          code: "",
          password: "",
          confirmPassword: ""
        };
        this.getCompanyDetail();
      })
    },
    getCompanyDetail() {
      getOfficeCompany(
        this.accountForm.authCompany.companyId, 
        this.$route.query.accessToken
      ).then((response) => {
        console.log(response);
        this.companyForm = {
          ...response,
          companyTimeZone: response.timeZone,
          companyCountry: response.country
        }
      }).catch((error) => {});
    },
    getDict() {
      getDict(this.$route.query.accessToken).then((response) => {
        this.country = response["COUNTRY"];
        this.timeZone = response["TIME_ZONE"];
        this.companyType = response["COMPANY_TYPE"];
      }).catch((error) => {});
    },
    prev() {
      this.activeType = 0;
    },
    userNext() {
      this.$refs.profileFormRef.validate((valid) => {
        if(!valid) return;
        if(!this.accountForm.code) {
          this.$message.warning(this.$t('Please verify the email first'));
          return;
        }
        this.activeType = 1;
      })
    },
    submit() {
      this.$refs.companyFormRef.validate((valid) => {
        if(!valid) return;
        let obj = {
          code: this.accountForm.code,
          country: this.accountForm.country,
          timeZone: this.accountForm.timeZone,
          nickName: this.accountForm.nickName,
          position: this.accountForm.position,
          avatarFileId: this.accountForm.avatarFileId,
          username: this.accountForm.username,
          password: this.accountForm.password,
          userEmail: this.accountForm.userEmail,
          vat: this.companyForm.vat,
          aliasName: this.companyForm.aliasName,
          contact: this.companyForm.contact,
          companyCountry: this.companyForm.companyCountry,
          email: this.companyForm.email,
          name: this.companyForm.name,
          companyTimeZone: this.companyForm.companyTimeZone,
          companyLogoId: this.companyForm.companyLogoId,
          address: this.companyForm.address,
          website: this.companyForm.website,
        };
        updateOauthTempUserData(obj, this.$route.query.accessToken).then(() => {
          this.$message.success(this.$t('Successfully taken over, please log in again'));
          this.$router.push("/login");
        });
      })
    }
  },
}
</script>

<style lang="less" scoped>
.transferTakeover {
  background: #E0E4EA;
  width: 100%;
  height: 100vh;
  padding: 20px;
  .card {
    background: #ffffff;
    border-radius: 4px;
    width: 100%;
    height: calc(100vh - 40px);
    .header {
      width: 100%;
      height: 102px;
      border-bottom: 1px solid #E0E4EA;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 200px;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        left: 50%;
        display: block;
        width: 32px;
        height: 1px;
        background: #E0E4EA;
      }
      .item {
        display: flex;
        align-items: center;
        gap: 8px;
        .index {
          background: #A3B0C6;
          width: 18px;
          height: 18px;
          color: #ffffff;
          font-size: 12px;
          line-height: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 600;
          border-radius: 50%;
        }
        font-size: 16px;
        color: #A3B0C6;
        font-weight: 400;
        line-height: 22px;
        &.active {
          font-weight: 600;
          color: #076F49;
          .index {
            background: #076F49;
          }
        }
      }
    }
    .form {
      padding-top: 66px;
      padding-bottom: 20px;
      width: 100%;
      height: calc(100vh - 142px);
      overflow: overlay;
      display: flex;
      flex-direction: column;
      align-items: center;
      &.company {
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        gap: 94px;
        padding-top: 80px;
        .formBox {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 0;
        }
      }
      .bindEmail {
        color: #076F49;
        padding-right: 6px;
        cursor: pointer;
      }
      .userAvatarBox {
        display: flex;
        align-items: center;
        gap: 13px;
        &.company {
          flex-direction: column;
          .imageBox {
            width: 120px;
            height: 89px;
            border-radius: 4px;
            background: #f4f4f4;
            img {
              object-fit: contain;
            }
          }
        }
        .imageBox {
          width: 64px;
          height: 64px;
          border-radius: 50%;
          overflow: hidden;
          position: relative;
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
          }
          .mask {
            background: rgba(0,0,0,.6);
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0;
            i {
              font-size: 20px;
              color: #ffffff;
            }
            input {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              opacity: 0;
              cursor: pointer;
            }
          }
          &:hover {
            .mask {
              opacity: 1;
            }
          }
        }
        .name {
          font-size: 20px;
          font-weight: 600;
          line-height: 28px;
          color: #122545;
        }
      }
      .formBox {
        width: 700px;
        margin-top: 40px;
        .profileForm {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          .el-form-item {
            width: 46%;
            margin-bottom: 30px;
            .title {
              font-size: 14px;
              color: #A3B0C6;
              line-height: 19.6px;
              margin-bottom: 5px;
              height: 20px;
            }
          }
        }
      }
    }
  }
}
.prevBtn {
  margin-top: 50px;
  font-size: 16px;
  color: #637381;
  line-height: 22.4px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
</style>