<template>
  <el-dialog 
    title=""
    :visible.sync="isEmailValidator"
    width="358px"
    :before-close="close">
    <div class="validatorEmailBox">
      <img src="https://file-echola.oss-cn-hangzhou.aliyuncs.com/webManage/image/noticEmail.png" alt="">
      <div class="title">{{ $t('Enter email verification code') }}</div>
      <div class="codeInput">
        <el-input v-model="codeInput1" ref="codeInput1" :maxlength="1" @input.native="codeInput($event, 1)" @keydown.native="handleKeyDown($event, 1)" />
        <el-input v-model="codeInput2" ref="codeInput2" :maxlength="1" @input.native="codeInput($event, 2)" @keydown.native="handleKeyDown($event, 2)" />
        <el-input v-model="codeInput3" ref="codeInput3" :maxlength="1" @input.native="codeInput($event, 3)" @keydown.native="handleKeyDown($event, 3)" />
        <el-input v-model="codeInput4" ref="codeInput4" :maxlength="1" @input.native="codeInput($event, 4)" @keydown.native="handleKeyDown($event, 4)" />
      </div>
      <el-button 
        type="primary" 
        size="small"
        :loading="btnLoading"
        @click="sendEmail"
      >{{ countDown > 0 ? countDown + 's' : $t('Send verification code') }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { sendEmailAuth, validatorEmail } from "@/api";
export default {
  name: "validatorEmail",
  props: {
    code: {
      type: String | Number,
      default: ""
    }
  },
  data() {
    return {
      btnLoading: false,
      email: "",
      countDown: 0,
      isEmailValidator: false,
      codeInput1: "",
      codeInput2: "",
      codeInput3: "",
      codeInput4: "",
    }
  },
  methods: {
    open(email) {
      this.email = email;
      this.isEmailValidator = true;
      this.$nextTick(() => {
        this.$refs.codeInput1.focus();
      });
      document.addEventListener('paste', this.pasteEvent);
    },
    pasteEvent(event) {
      let text = (event.clipboardData || window.clipboardData).getData('Text');
      let textSplit = text.split("");
      if(textSplit.length == 4) {
        this.codeInput1 = textSplit[0] || '';
        this.codeInput2 = textSplit[1] || '';
        this.codeInput3 = textSplit[2] || '';
        this.codeInput4 = textSplit[3] || '';
        this.codeInput(4);
      }
    },
    close() {
      document.removeEventListener("paste", this.pasteEvent);
      this.isEmailValidator = false;
    },
    sendEmail() {
      if (this.countDown > 0) return;
      this.btnLoading = true;
      sendEmailAuth(this.email, this.$route.query.accessToken).then(() => {
        this.countDown = 60;
        const timer = setInterval(() => {
          this.countDown--;
          if (this.countDown <= 0) {
            clearInterval(timer);
          }
        }, 1000);
      }).finally(() => {
        this.btnLoading = false;
      })
    },
    handleKeyDown(event, type) {
      if(event.key == 'Backspace' && !event.target.value && type > 1) {
        setTimeout(() => {
          this.$refs['codeInput'+(type-1)].clear();
          this.$refs['codeInput'+(type-1)].focus();
        }, 10);
      }
    },
    codeInput(event, type) {
      if(event.inputType == 'deleteContentBackward') return;
      if(type != 4) {
        this.$refs['codeInput'+(type+1)].focus();
      } else {
        validatorEmail({
          email: this.email,
          code: `${this.codeInput1}${this.codeInput2}${this.codeInput3}${this.codeInput4}`
        }).then((data) => {
          if(data) {
            this.$message.success(this.$t('Validate success'));
            this.$emit("update:code", `${this.codeInput1}${this.codeInput2}${this.codeInput3}${this.codeInput4}`);
            this.$nextTick(() => {
              this.codeInput1 = "";
              this.codeInput2 = "";
              this.codeInput3 = "";
              this.codeInput4 = "";
            });
            this.close();
          } else {
            this.$message.error(this.$t('Validate failed'));
            this.$nextTick(() => {
              this.codeInput1 = "";
              this.codeInput2 = "";
              this.codeInput3 = "";
              this.codeInput4 = "";
              this.$refs.codeInput1.focus();
            });
          }
        });
      }
    },
  },
}
</script>

<style lang="less" scoped>
.validatorEmailBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 16px 0 16px;
  img {
    width: 65px;
    margin-bottom: 17px;
  }
  .title {
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    color: #122545;
    margin-bottom: 40px;
  }
  .codeInput {
    display: flex;
    justify-content: space-between;
    gap: 12px;
    /deep/ .el-input__inner {
      border: none;
      background: #F7F9FC;
    }
  }
  .el-button {
    width: 100%;
    margin-top: 40px;
    margin-bottom: 25px;
  }
}
</style>